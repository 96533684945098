<template>
  <div>
    <b-card>
      <b-card class="mb-0">
        <div class="d-flex justify-content-between mb-1">
          <h3>Filters</h3>
          <div class="d-flex" style="gap: 10px" @click="resetFilters">
            <div
              style="height: 30px"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                cursor-pointer
                px-2
                rounded-sm
                shadow-sm
                bg-primary
                text-white
              "
            >
              Reset Filters
            </div>
          </div>
        </div>

        <b-row style="gap: 10px 0">
          <b-col cols="12">
            <b-form-input
              v-model="rec_filters.search"
              placeholder="Search"
              @input="onFilterChange"
            />
          </b-col>
          <b-col cols="3">
            <v-select
              v-model="rec_filters.university_id"
              placeholder="University"
              :options="universities"
              clearable
              label="university_name"
              :reduce="(item) => item.university_id"
              @input="onFilterChange"
            />
          </b-col>
          <b-col cols="3">
            <v-select
              v-model="rec_filters.counselor_id"
              placeholder="Counselor"
              clearable
              :options="
                counselors.map((counselor) => ({
                  value: counselor.user_id,
                  label: `${counselor.user_firstname} ${counselor.user_lastname}`,
                }))
              "
              :reduce="(item) => item.value"
              @input="onFilterChange"
            />
          </b-col>
          <b-col cols="3">
            <v-select
              v-model="rec_filters.course_level_id"
              placeholder="Level"
              :options="levels"
              clearable
              label="course_level_name"
              :reduce="(item) => item.course_level_id"
              @input="onFilterChange"
            />
          </b-col>
          <b-col cols="3">
            <v-select
              v-model="rec_filters.course_category_id"
              placeholder="Discipline"
              :options="disciplines"
              clearable
              label="course_category_name"
              :reduce="(item) => item.course_category_id"
              @input="onFilterChange"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-card class="" style="">
        <b-table
          style="max-height: 60vh"
          :fields="tableColumns"
          :items="recommendations"
          responsive
          sticky-header
          show-empty
          empty-text="No Data Available"
          :busy="isLoading"
          class="custom-table"
        >
          <template #cell(university_name)="data">{{
            data.item.university_name
          }}</template>
          <template #cell(recommended_count)="data">
            <span @click="viewDetails(data.item.university_id)">
              {{ data.item.recommended_count }}</span
            >
          </template>
          <template #cell(favourites_count)="data">
            <span></span>
            {{ data.item.favourites_count }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              {{ recommendations.length }} entries
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              aria-controls="UniListTable"
              v-model="pagination.currentPage"
              :total-rows="totalRecommendations"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
        <div class="loader" v-if="isLoading">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import CounselorServices from "@/apiServices/CounselorServices";
import HomeServices from "@/apiServices/HomeServices";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "Recommendation",
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BTooltip,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      showFiltersMembers: false,
      showFilters: false,
      totalRecommendations:0,
      recommendations: [],
      universities: [],
      levels: [],
      disciplines: [],
      counselors: [],
      debouce: null,
      tableColumns: [
        { key: "university_name", label: "University", sortable: false },
        {
          key: "recommended_count",
          label: "Recommended Course Count",
          sortable: false,
        },
        { key: "favourites_count", label: "Favourites Count", sortable: false },
      ],
      rec_filters: {
        search: "",
        university_id: null,
        counselor_id: null,
        franchise_id: null,
        course_level_id: null,
        course_category_id: null,
      },
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 20, 30],
      },
    };
  },
  methods: {
    async getRecommmendations() {
      try {
        const res = await CounselorServices.recommendation({
          ...this.rec_filters,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage,
        });
        if (res.data.status) {
          this.recommendations = res.data.data.data;
          this.totalRecommendations = res.data.data.count
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCourseLevels() {
      try {
        const res = await HomeServices.getCourseLevels();
        if (res.data.status) {
          this.levels = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDiscipline() {
      try {
        const res = await HomeServices.getCourseCategories();
        if (res.data.status) {
          this.disciplines = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCounselors() {
      try {
        const res = await CommonServices.getAllCounselorsCommon();
        if (res.data.status) {
          this.counselors = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    counselorLabel(item) {
      if (!item) return "";
      return `${item.user_firstname} (${item.user_lastname})`;
    },

    async getUniversities() {
      try {
        const res = await CommonServices.getAllUniversities();
        if (res.data.status) {
          this.universities = res.data.data;
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    },

    resetFilters() {
      this.rec_filters = {
        search: "",
        university_id: null,
        counselor_id: null,
        franchise_id: null,
        course_level_id: null,
        discipline_id: null,
      };
    },
    onFilterChange() {
      this.getRecommmendations();
    },

    // watch: {
    //   "rec_filters.university_id"(newValue, oldValue) {
    //     this.getFilteredRecommendations();
    //   },
    viewDetails(university_id) {
      // console.log(university_id);
      this.$router.push({
        name: "RecommendedStudents",
        query: { university_id: university_id },
      });
    },
  },
  watch: {
  "pagination.currentPage": "getRecommmendations",
},

  beforeMount() {
    this.getRecommmendations();
    this.getUniversities();
    this.getCourseLevels();
    this.getDiscipline();
    this.getCounselors();
  },
};
</script>
